@import 'scss/mixins';
@import 'scss/colors';

.LoginView {
  @include full;
  @include flex-column;

  padding: 2rem;
  text-align: center;
  width: 100%;
  margin: auto;
  background-color: white;

  h2,
  img {
    margin-bottom: 3rem;
  }
}
