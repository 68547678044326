@import 'scss/mixins';
@import 'scss/colors';

.UserDataFormView {
  width: 500px;
  max-width: 100%;
  margin: auto;
  padding: 2rem;

  .bp3-form-helper-text {
    color: $red4 !important;
  }

  label .bp3-text-muted {
    color: $red4 !important;
  }
}
