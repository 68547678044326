@import 'scss/mixins';
@import 'scss/colors';

.ThreadView {
  @include flex-column;
  height: calc(100vh - 50px);

  @media (min-width: 801px) {
    height: calc(100vh - 120px);
  }

  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 167px;
}
