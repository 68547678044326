@import 'scss/mixins';
@import 'scss/colors';

.ChatInput {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid $light-gray1;
  background-color: $light-gray4;

  &__MainContainer {
    @include flex;
    padding: 8px;
    height: 116px;
    gap: 8px;

    align-items: flex-end;

    position: relative;

    textarea {
      height: 100px;
      min-height: 100px;
      flex-grow: 1;
    }

    button {
      flex-grow: 0;
      width: 30px;
      height: 30px;
    }

    .bp3-spinner {
      position: absolute;
      top: 50%;
      left: calc(50% - 38px);
      transform: translate(-50%, -50%);
    }
  }

  &__Attachments {
    height: 50px;
    padding: 8px 8px 0;
    position: relative;

    &.hidden {
      display: none;
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed !important;
      }
    }
  }

  &__Input {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 0;
    height: calc(100% - 8px) !important;
    pointer-events: none;

    &.disabled {
      background: rgba(206, 217, 224, 0.5);
      box-shadow: none;
      cursor: not-allowed !important;
    }

    &__Background {
      @include position-absolute-zeros;
      @include flex;
      justify-content: flex-start;

      padding: 4px 8px;
      gap: 8px;

      color: $gray5;

      * {
        line-height: 1rem;
      }

      svg {
        font-size: 1.5rem;
      }
    }

    &__Attachments {
      @include flex;
      @include position-absolute-zeros;
      z-index: 10;
      padding: 4px 8px;
      justify-content: flex-start;
      gap: 4px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
