@import 'scss/mixins';
@import 'scss/colors';

.ThreadsList {
  @include flex-column;
  width: 300px;
  height: calc(100vh - 50px);

  @media (min-width: 801px) {
    height: calc(100vh - 120px);
  }

  align-items: flex-start;
  justify-content: flex-start;

  overflow-y: auto;
  overflow-x: hidden;

  border-right: 1px solid $light-gray1;

  padding: 0.5rem;

  position: relative;

  .bp3-control-group {
    margin-bottom: 8px;
  }
}
