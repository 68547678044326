@import 'scss/mixins';
@import 'scss/colors';

.AttachmentCard {
  height: 34px;
  aspect-ratio: 1;
  border: 1px solid $light-gray1;
  border-radius: 4px;
  background-color: white;
  pointer-events: all;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  &__Remove {
    @include position-absolute-zeros;
    @include flex;
    display: none;
    color: $red1;
  }

  &:hover {
    img {
      opacity: 0.3;
    }

    .AttachmentCard__Remove {
      display: flex;
    }
  }
}
