@import 'scss/mixins';
@import 'scss/colors';

.ErrorView {
  @include full;
  @include flex;
  padding: 2rem;

  .bp3-card {
    width: 100%;
    text-align: center;
    color: $red3;
    font-weight: bold;
  }
}
