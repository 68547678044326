@import 'scss/mixins';
@import 'scss/colors';

.ContactItem {
  @include flex;
  width: 300px - 16px;
  padding: 0.25rem;
  border-bottom: 1px solid $light-gray1;
  cursor: pointer;

  justify-content: flex-start;
  gap: 0.5rem;

  &:last-child {
    border-bottom: none;
  }

  &__Content {
    flex-shrink: 1;
    width: 300px - 16px - 8px - 32px - 8px - 30px - 30px;
    height: 100%;

    & > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  &__Icons {
    @include flex;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: flex-end;
    height: 100%;
    aspect-ratio: 2;
  }

  .Avatar {
    flex-shrink: 0;
    flex-grow: 0;
  }

  &:hover {
    background-color: $light-gray3;
  }

  &.selected {
    background-color: $light-gray2;

    &:hover {
      background-color: $light-gray1;
    }
  }
}
