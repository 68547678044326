@import 'scss/mixins';
@import 'scss/colors';

.WriteToAdminButton {
  position: absolute;
  bottom: 8px;
  right: 8px;

  width: 60px;
  aspect-ratio: 1;
}
