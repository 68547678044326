@import 'scss/mixins';
@import 'scss/colors';

.ChatMessage {
  @include flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  gap: 8px;
  width: 100%;

  .Avatar {
    flex-shrink: 0;
    margin-top: 2px;
  }

  &__Container {
    flex-grow: 1;
    width: 100%;
    white-space: pre-wrap;
  }

  &__Files {
    @include flex;
    padding-top: 8px;
    gap: 4px;
    justify-content: flex-start;
  }
}
