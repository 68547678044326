@import 'scss/mixins';
@import 'scss/colors';

.ReadIndicator {
  @include flex;
  width: 100%;
  height: 16px;
  position: relative;
  padding: 0 calc(5% + 16px);
  justify-content: flex-end;
  gap: 4px;

  &__Line {
    width: 90%;
    height: 1px;
    background-color: $light-gray1;
    position: absolute;
    left: 5%;
    top: 50%;
  }

  &__Text {
    position: absolute;
    left: 50%;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray4;
    font-size: 10px;
    transform: translateX(-50%);
    z-index: 10;
    background-color: $light-gray5;
    padding: 0 4px;
  }

  &__Avatar {
    @include elevated;
    height: 16px;
    aspect-ratio: 1;
    border-radius: 99999px;
    z-index: 10;
    background-color: $light-gray4;

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 99999px;
      opacity: 0.7;
    }
  }
}
