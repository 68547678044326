@import 'scss/mixins';
@import 'scss/colors';

.UserAttachments {
  width: 100%;

  td.centered {
    padding-left: 2rem;

    svg {
      cursor: pointer;
    }
  }

  table {
    width: 100%;
  }
}
