@mixin full {
  width: 100%;
  min-height: 100vh;
}

@mixin full-page {
  width: 100%;
  min-height: calc(100vh - 50px);

  @media (min-width: 801px) {
    height: calc(100vh - 120px);
  }
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin flex-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@mixin fill {
  width: 100%;
  height: 100%;
}

@mixin position-absolute-zeros {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin position-absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-fixed-zeros {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin position-fixed-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin rounded($radius: 8px) {
  border-radius: $radius;
}

@mixin circle {
  border-radius: 999999999999px;
}

@mixin rounded-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@mixin rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@mixin elevated {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
    0 2px 6px rgba(16, 22, 26, 0.2);
}

@mixin animated($time: 0.7s) {
  transition: all $time ease-in-out;
}

@mixin clickable {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: inherit;
  }

  &:hover::before {
    background: rgba(white, 0.1);
    // box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
    //   0 2px 6px rgba(16, 22, 26, 0.2);
  }

  &:active::before {
    background: rgba(white, 0.2);
    // box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
    //   0 2px 6px rgba(16, 22, 26, 0.2);
  }
}

@mixin clickable-dark {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: inherit;
  }

  &:hover::before,
  &:focus::before {
    background: rgba(black, 0.1);
    // box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
    //   0 2px 6px rgba(16, 22, 26, 0.2);
  }

  &:active::before {
    background: rgba(black, 0.2);
    // box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
    //   0 2px 6px rgba(16, 22, 26, 0.2);
  }
}
