@import './colors.scss';
@import './mixins.scss';

body {
  padding: 0;
  margin: 0;
  background-color: $light-gray2;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  width: 1200px;
  max-width: 100vw;
  margin: auto;
  background-color: $light-gray5;
}

.circle-button {
  border-radius: 9999999px;
  padding: 5px !important;
}

.bp3-datepicker {
  @include flex;
  .DayPicker-Months {
    .DayPicker-Caption {
      .bp3-datepicker-caption {
        .bp3-html-select {
          & > span {
            display: none;
          }
        }
      }
    }
  }
}
