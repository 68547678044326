@import 'scss/mixins';
@import 'scss/colors';

.LoggedView {
  @include flex-column;
  @include full;

  .bp3-navbar {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    @media (min-width: 801px) {
      height: 120px;
    }
  }

  .bp3-navbar-group {
    @include flex;
    gap: 0.5rem;

    &.Logo {
      height: 40px;
      margin-bottom: 6px;
      aspect-ratio: 487 / 182;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 100%;
        height: 100%;
      }

      @media (min-width: 801px) {
        height: 100px;
        margin-bottom: 8px;
      }
    }

    @media (max-width: 800px) {
      .bp3-text-muted {
        display: none;
      }
    }
  }

  &__Content {
    @include fill;
    @include flex;

    justify-content: flex-start;
    flex-grow: 1;

    & > .ContactsList {
      flex-grow: 0;
      flex-shrink: 0;
    }

    & > .ThreadsList {
      flex-grow: 0;
      flex-shrink: 0;
    }

    & > .ThreadView {
      flex-grow: 1;
      flex-shrink: 0;
    }

    & > .ProfileView {
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
