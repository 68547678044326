@import 'scss/mixins';
@import 'scss/colors';

.InitView {
  @include flex-column;
  height: calc(100vh - 50px);
  width: 100%;
  padding: 1rem;
  justify-content: flex-start;

  @media (min-width: 801px) {
    height: calc(100vh - 120px);
  }

  .SelectNotify {
    @include elevated;
    @include rounded;
    @include flex;
    background-color: $pt-intent-primary;
    color: white;
    padding: 0 1rem;
    width: 100%;
    height: 3rem;
    justify-content: space-between;

    svg {
      fill: white;
      color: white;
    }
  }
}
