@import 'scss/mixins';
@import 'scss/colors';

.SecurityView {
  @include flex-column;
  height: calc(100vh - 50px);
  width: 100%;

  @media (min-width: 801px) {
    display: grid;
    height: calc(100vh - 120px);
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
  }

  & > div {
    @include flex-column;
    width: 100%;
    height: 100%;
    padding: 1rem;

    ul {
      width: 100%;
    }

    li {
      list-style: none;
      width: 100%;

      b {
        color: $pt-intent-success;
      }

      .bp3-spinner {
        display: inline-block;
        width: auto;
        margin-top: -2px;
      }
    }
  }

  & > div:nth-child(1),
  & > div:nth-child(3) {
    & > div {
      @include flex;
      width: 80%;
      max-width: 200px;
      background-color: $light-gray1;
      aspect-ratio: 1;
      border-radius: 999999px;
      img {
        width: 70%;
      }
    }
  }

  & > div:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-bottom: 1px solid $light-gray3;
  }
  & > div:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    border-bottom: 1px solid $light-gray3;
  }
  & > div:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  & > div:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}
