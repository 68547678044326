@import 'scss/mixins';
@import 'scss/colors';

.Avatar {
  @include circle;
  @include elevated;
  @include clickable;

  outline: none;
  border: none;
  padding: 0;
  display: block;

  background: transparent;

  img {
    @include circle;

    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
