@import 'scss/mixins';
@import 'scss/colors';

.ProfileView {
  @include flex-column;
  height: calc(100vh - 50px);
  width: calc(100% - 300px);

  @media (min-width: 801px) {
    height: calc(100vh - 120px);
  }

  position: relative;

  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  padding: 1rem;

  .Avatar {
    z-index: 1;
    align-self: center;
  }

  .UnderAvatar {
    background-color: $light-gray5;
    width: 100%;
    height: 66px;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid $light-gray3;
  }
}
